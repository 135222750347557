import { render, staticRenderFns } from "./technical.component.html?vue&type=template&id=09bf3d51&scoped=true&"
import script from "./technical.component.js?vue&type=script&lang=js&"
export * from "./technical.component.js?vue&type=script&lang=js&"
import style0 from "./technical.component.scss?vue&type=style&index=0&id=09bf3d51&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09bf3d51",
  null
  
)

export default component.exports