import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import Toolbar from '@/components/toolbar/toolbar';


export default {
    name: "Technical",
    data() {
        return {
            group_technical: [
                { id: 1, name_tab: "โบรชัวร์" },
                { id: 2, name_tab: "ข้อมูลด้านประสิทธิภาพ" },
                { id: 3, name_tab: "มอก." },
                { id: 4, name_tab: "ข้อแนะนำผลิตภัณฑ์" },
                { id: 5, name_tab: "ข้อจำกัดความรับผิดชอบ" },
                { id: 6, name_tab: "NIS" },
            ],
            list_selected: [],
            list_technical: [],
            list_law: [
                { id: 1, name_law: "พระราชบัญญัติควบคุมอาคาร ฉบับที่ 5 พ.ศ. 2558", url: "" },
                { id: 2, name_law: "พระราชบัญญัติควบคุมอาคาร ฉบับที่ 4 พ.ศ. 2550", url: "" },
                { id: 3, name_law: "พระราชบัญญัติควบคุมอาคาร ฉบับที่ 3 พ.ศ. 2543", url: "" },
                { id: 4, name_law: "พระราชบัญญัติควบคุมอาคาร ฉบับที่ 3 พ.ศ. 2543", url: "" },
                { id: 5, name_law: "กฎกระทรวง กำหนดประเภทหรือขนาดของอาคาร", url: "" },
                { id: 6, name_law: "กฎกระทรวง ฉบับที่ 48 ออกตามความในพระราชบัญญัติควบคุมอาคาร", url: "" },
            ],
            current_index: 0,
            current_tab: 1,
            baseFront: this.$baseFront
        }
    },
    methods: {
        FetchTechnicalList(id,tab) {
            this.$refs['download'][0].focus()
            this.current_tab = tab
            // console.log(this.$refs['download'][0].focus());
            this.list_selected = JSON.parse(this.list_technical.find(i=>i.id == id).parameter_file_download)
        },
        openLink(url) {
            console.log(url);
            window.open(url, '_blank')
        },
        async fetchTechnical() {
            await this.$axios.get("/api/show/technical")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.list_technical = response.data.data
                    }
                })
        },
        async fetchTechnicalLaw() {
            await this.$axios.get("/api/show/technical_law")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.list_law = response.data.data[0]
                        console.log(this.list_law);
                        this.list_law.file_download = this.list_law.parameter_file_download == "" ? [] : JSON.parse(this.list_law.parameter_file_download)
                    }
                })
        },
        async fetchGroupTechnical() {
            await this.$axios.get("/api/show/group_technical")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.group_technical = response.data.data
                        this.list_selected = JSON.parse(this.list_technical.find(i=>i.id == this.group_technical[0].ref_technical_image_1).parameter_file_download)
                    }
                })
        },
    },
    async mounted() {
        await this.fetchTechnical()
        await this.fetchGroupTechnical()
        await this.fetchTechnicalLaw()
        document.title = "AGC - technical";
        AOS.init();
    },
    components: {
        Footer, Header, Breadcrumb, Toolbar
    }
};